#latest-news {
  padding: 40px 0;

  .block-title {
    margin-bottom: 40px;

    h2 {
      background: $white;
      display: inline-block;
      padding: 5px 80px;
      margin: 0;
      border: 1px solid $gray-lighter;
      font-family: "Roboto Slab";
    }
  }

  ul {
    margin: 0;
    padding: 0;
    @include grid(1, 1, 2, 3, 3, 40px);
    list-style: none;

    li {
      background: $white;
      position: relative;
      @extend .box-shadow;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .date {
        position: absolute;
        color: $white;
        background: $blue;
        padding: 5px 10px;
        left: 20px;
        top: -20px;
      }

      .block-content {
        padding: 20px;
        position: relative;
        align-content: stretch;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        p {
          color: $gray-light;
        }

        .button-holder {
          margin-top: auto;
        }

      }

      @include respond-between(md, lg) {
        &:last-child {
          display: none;
        }
      }
    }
  }
}

.overview {
  padding: 40px 0;

  .wrapper {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      @include grid(1,2,2,3,4, 40px);

      li {
        background: $white;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        @extend .box-shadow;

        .intro {
          padding: 20px;
          position: relative;
          display: flex;
          align-content: stretch;
          flex-grow: 1;
          flex-direction: column;

          .date {
            position: absolute;
            color: $white;
            background: $blue;
            padding: 5px 10px;
            left: 20px;
            top: -20px;
          }

          h3 {
            margin-top: 0;
          }
          p {
            color: $gray-light;
          }
          div {
            margin-top: auto;
          }
        }
      }
    }
  }
}

.detail {
  padding: 40px 0;

  .wrapper {
    .content {
      padding: 40px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @supports(display: grid){
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-column-gap: 40px;
      }

      .sidebar {
        flex: 0 0 33%;
        padding-right: 20px;
        img {
          margin-bottom: 20px;
        }
      }
      .main {
        flex: 0 0 100%;
        @supports(display: grid){
          grid-column: 1 / -1;
        }

        h2 {
          color: $blue;
          font-size: 1.5rem;
          margin-top: 0;
          padding-bottom: 15px;
          border-bottom: 1px solid $gray-lighter;
        }
      }
      .sidebar + .main {
        grid-column: auto;
        flex: 0 0 67%;
      }
    }
  }
}
