.product-groups {
  padding: 40px 0;

  > .wrapper {
    background: $white;
    

    @supports(display: grid){
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 50px auto;
      grid-column-gap: 0;
    }

    @include respond-below(xl) {
      margin: 0 40px;
      padding: 0;
      width: calc(100% - 80px);
    }

    @include respond-below(md) {
      margin: 0 20px;
      padding: 0;
      width: calc(100% - 40px);
    }

    .feature-title {
      background: $blue;
      color: $white;
      padding: 10px 20px;
      font-size: 1.25rem;
      font-weight: 700;
    }

    .carousel-buttons {
      border-bottom: 1px solid $gray-lighter;
      padding-right: 40px;
      padding-left: 40px;

      @include respond-below(md) {
        padding-right: 20px;
        padding-left: 20px;
      }

      @include respond-below(sm) {
        grid-row: 3;
        padding-left: 20px;
      }

      h2 {
          float: left;
          color: $blue;
          margin: 0;
          padding-top: 10px;
          font-size: 1.25rem;
      }

      .button-group {
        padding: 10px 0;
        float: right;

        a {
          color: $white;
          float: left;
          margin-left: 5px;
          padding: 2px 7px 0 7px;
          background: $green;
        }
      }
    }

    .feature {
      background-size: cover;
      background-position: center;

      .feature-content {
        padding: 20px;

        form {
          * {
            color: $white;
          }

          .nf-form-fields-required {
            display: none;
          }

          label {
            text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
            color: $white;
            font-size: 1.25rem;
          }

        }

        p {
          text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
          color: $white;
          font-size: 2rem;
        }

        a,
        .submit-wrap input {
          @include button;

          background: $red;
          color: $white;
          border: none;
          display: inline-block;
          text-shadow: none;
        }
      }
    }
    .carousel-holder{
      overflow: hidden;
      padding-left: 40px;

      @include respond-below (md) {
        padding-left: 20px;
      }

      @include respond-below(sm) {
        padding-left: 0;
      }

      .carousel {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          display: flex;
          position: relative;
          left: -25%;
          transform: translateX(25%);

          &.is-set {
            transform: none;
            transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
          }

          &.is-reversing {
            transform: translateX(-25%);

            &.is-set {
              transform: none;
              transition: transform 0.5s cubic-bezier(0.23, 1, 0.32, 1);
            }
          }

          li {
            text-align: center;
            padding: 20px 0;
            padding-right: 40px;
            flex: 1 0 25%;
            order: 2;

            h3 {
              color: $black;
              // text-transform: uppercase;
              font-weight: $font-weight-bold;
            }

            &.is-ref {
              order: 1;
            }
          }

          @include respond-below (xl) {
            left: -33.33%;
            transform: translateX(33.33%);

            &.is-reversing {
              transform: translateX(-33.33%);
            }

            li {
              flex: 1 0 33.33%;
            }
          }

          @include respond-below (lg) {
            left: -50%;
            transform: translateX(50%);

            &.is-reversing {
              transform: translateX(-50%);
            }

            li {
              flex: 1 0 50%;
            }
          }

          @include respond-below (md) {
            left: -100%;
            transform: translateX(100%);

            &.is-reversing {
              transform: translateX(-100%);
            }

            li {
              flex: 1 0 100%;
              padding-right: 20px;
            }
          }
          @include respond-below (sm) {
            li {
              padding: 20px;
            }
          }
        }
      }
    }
  }

  &.featured {
    > .wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      > *:nth-child(odd){
        flex: 0 0 20%;
      }

      > *:nth-child(even){
        flex: 0 0 80%;
      }

      @supports(display: grid){
        grid-template-columns: minmax(200px, 250px) 1fr;
      }

      @include respond-below(xl) {
        margin: 0 40px;
        padding: 0;
        width: calc(100% - 80px);
        > *:nth-child(odd){
          flex: 0 0 25%;
        }
    
        > *:nth-child(even){
          flex: 0 0 75%;
        }
      }
      @include respond-below(lg){
        > *:nth-child(odd){
          flex: 0 0 30%;
        }
    
        > *:nth-child(even){
          flex: 0 0 70%;
        }
      }

      @include respond-below(md) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0;
      }

      @include respond-below(sm) {
        grid-template-columns: 1fr;
      }
    }
  }
}
