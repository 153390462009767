.products-row {
  margin-bottom: 40px;
  .wrapper {

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      border-top: 1px solid $gray-lighter;
      border-left: 1px solid $gray-lighter;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      
      @supports(display: grid){
        @include grid(2,2,2,4,4,0);
      }
      

      li {
        background: $white;
        padding: 20px;
        padding-top: 10px;
        text-align: center;
        border-right: 1px solid $gray-lighter;
        border-bottom: 1px solid $gray-lighter;
        height: 100%;
        flex: 0 0 25%;

        @include respond-below(lg) {
          padding: 20px;
        }

        @include respond-below(sm) {
          padding: 10px;
        }

        img {
          margin: 10px auto;
          max-width: 70%;
        }

        h3 {
          color: $black;
          font-size: 1.25rem;
          margin: 0;
          // text-transform: uppercase;

          &.code {
            margin-bottom: 10px;
          }
        }

        h4 {
          margin: 0;
          font-size: 1rem;

          &.inhoud {
            margin-top: 10px;
          }
        }

        &.row-title {
          text-align: left;
          background: $blue;
          padding: 10px 20px;
          position: relative;
          border: 0;
          display: flex;
          flex: 0 0 100%;
          flex-flow: row;
          justify-content: space-between;

          @supports(display: grid){
            grid-column: 1 / -1;
          }

          h2 {
            color: $white;
            font-size: 1.5rem;
            margin: 0;
          }

          > div {

          }
        }
      }
    }
  }

  &.new-products {
    .wrapper {
      ul {
        li {
          position: relative;

          .new {
            position: absolute;
            top: 5px;
            right: 40px;

            @include respond-below(xl) {
              right: 20px;
            }
          }

          &.row-title {
            background: $gray-lighter;

            h2 {
              color: $blue;
            }
          }
        }
      }
    }
  }

  &.tax-products {
    .wrapper {
      ul {
        @include grid(2,2,3,4,4,0);
      }
    }
  }
}

.product-info {
  padding-top: 40px;
  .wrapper {
    .info {
      background: $white;
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-gap: 40px;

      .left {
        padding: 40px;
      }

      .right {
        position: relative;
        padding-right: 40px;

        h1 {
          text-transform: uppercase;
          color: $blue;
          font-size: 4rem;
          margin: 20px 0;
          font-weight: $font-weight-black;
          padding-right: 80px;
        }
        .new {
          position: absolute;
          right: 40px;
          top: 30px;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          border-top: 1px solid $gray-lighter;
          border-bottom: 1px solid $gray-lighter;
          @include grid(1,3,3,3,3,20px);

          li {
            font-size: 1.25rem;
            padding: 10px 0;
            font-weight: $font-weight-light;

            span {
              font-weight: $font-weight-medium;
              margin-left: 5px;
            }
          }
        }
        .content {
          h4 {
            margin-bottom: 10px;
          }
          p {
            color: $gray-light;
          }
        }
      }
    }
  }

  @include respond-below(xl) {
    .wrapper {
      .info {
        .right {
          h1 {
            font-size: 2.75rem;
          }
          .new {
            top: 25px;
          }
          ul {
            li {
              span {
                width: 100%;
                display: inline-block;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }

  @include respond-below(lg) {
    .info {
      grid-template-columns: 1fr 1fr;
    }
  }

  @include respond-below(md) {
    .wrapper {
      .info {
        grid-template-columns: 1fr;
        grid-gap: 0;

        .left {
          padding: 0 20px;

          img {
            max-width: 320px;
            margin: 0 auto;
          }
        }
        .right {
          padding: 0 20px;

          ul {
            grid-gap: 0;
          }

          h1 {
            padding-right: 60px;
          }
          .new {
            right: 20px;
          }
        }
      }
    }
  }

}
