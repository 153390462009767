.box-shadow {
  box-shadow: 0px 5px 8px 1px rgba($gray-lighter, 1);
}

.button.read-more {
  border: 1px solid $gray-lighter;
  font-weight: $font-weight-bold;

  &:hover {
    border: 1px solid $gray-light;
  }
}

.centered {
  margin: 0 auto;
  text-align: center;
  display: block;
}

.new {
  background: $green;
  width: 70px;
  height: 70px;
  position: absolute;
  color: $white;
  padding: 20px 0;
  text-align: center;
  border-radius: 100%;
  font-weight: $font-weight-black;
  font-size: 1.25rem;

  @include respond-below (xl) {
    width: 50px;
    height: 50px;
    font-size: 1rem;
    padding: 15px 0;
  }

  @include respond-below (md) {

  }
}


.text-shadow {
  text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
}
