nav {
  width: 100%;
  z-index: 1000;

  .logo {
    position: absolute;
    left: 0;
    top: 0;
    width: 150px;

    > div {
        padding: 20px;
    }

    .logo-asiatico {
        background: $white;
    }

    .logo-kingfish {
        background: $white;
    }

    @include respond-below(lg) {
      top: 0;
      left: 10px;
      width: 100px;
      > div {
        padding: 10px;
      }
    }

    @include respond-between(md, lg) {
      left: 20px;
      transform: none;
    }
  }

  .top-menu {
    background: $secondary-color;
    width: 100%;
    float: left;
    height: 45px;
    text-align: center;

    ul {
      margin: 0 auto;
      list-style: none;
      padding-left: 0;
      display: inline-block;

      li {
        float: left;
        padding: 10px 20px;
        font-size: 1rem;
        color: $white;

        i {
            margin-right: 5px;
            color: $white;
        }

        a {
            text-decoration: none;
            font-weight: $font-weight-black;
            font-size: 1rem;
            margin: 0 5px;
        }
      }
    }

    @include respond-below(lg) {
      display: none;
    }
  }

  .main-menu {
    position: absolute;
    right: 0;
    top: 45px;
    background: rgba($blue, 0.3);
    height: 50px;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      height: 100%;
      float: left;

      li {
        float: left;
        padding: 10px 20px;
        min-width: 60px;
        height: 100%;

        a {
          font-size: 1.25rem;
          text-decoration: none;
        }

        &.active {
          font-weight: $font-weight-black;
        }

        &.language-toggler {
          background: $blue;
          padding: 0;
          overflow: hidden;
          transition: height 400ms;

          .label {
            display: none;
          }

          > ul {
            display: flex;
            flex-flow: column;

            li {
              float: left;
              clear: left;
              width: 100%;
              color: $white;
              text-transform: uppercase;
              font-size: 1.25rem;
              padding: 10px 20px;
              order: 2;

              i {
                margin-left: 10px;
                cursor: pointer;
                transition: transform 400ms;
              }

              &.active {
                display: block;
                order: 1;
              }
            }
          }

          &.open {
            height: 200px;

            i {
              transform: rotateX(180deg);
              transform-origin: 50% 50%;
            }
          }
        }

        &.mini-logo {
          display: none;
        }

        &.search-toggler {
          text-align: center;
          background: $white;
          position: relative;
          transition: all 400ms;

          a {
            color: $gray-darker;
          }

          .searchbar {
            position: absolute;
            top: 50px;
            height: 0;
            overflow: hidden;
            background: $white;
            width: 400px;
            right: 0;
            transition: all 400ms;

            form {
              padding: 5px;
              position: relative;

              label {
                display: none;
              }

              input {
                float: left;
                padding: 10px;
                border: 0;
              }

              a {
                 position: absolute;
                 z-index: 100;
                 right: 23px;
                 top: 10px;
                 cursor: pointer;
              }
            }
          }
          &.open {
            height: 100px;

            .searchbar {
              height: 50px;
            }
          }
        }
        &.menu-toggler {
          background: $red;
          text-align: center;
          display: none;
        }
      }
      &.menu-items {
        padding: 0 10px;
      }
    }
    @include respond-below(xl) {
      .menu-items {
        li {
          padding: 10px;

          a {
            font-size: 1.125rem;
          }
        }
      }
    }

    @include respond-below(lg) {
      top: 0;
      height: 45px;
      width: 100%;
      max-width: 400px;
      right: -100%;
      transition: right 500ms;

      &.is-open {
        right: 0;
        position: fixed;
        z-index: 100;
      }

      > ul {
        position: absolute;
        top: 0;
        background: $white;

        > li {
          width: 100%;
          height: auto;

          a {
            color: $blue;
          }
        }

        &.menu-items {
          margin-top: 115px;
          height: 100vh;
          padding-top: 65px;

          li {
            border-bottom: 1px solid $gray-lighter;
          }
        }

        &.togglers {
          display: block;
          width: 100%;

          li {
            width: 100%;

            &.mini-logo {
              background: $red;
              display: block;
              height: 60px;
              padding: 12px 0;
              text-align: center;

              img {
                max-height: 100%;
                margin: 0 auto;
              }
            }

            &.language-toggler {
              height: auto;

              .label {
                display: block;
                color: $white;
                float: left;
                padding: 17px 0 15px 20px;
                font-size: 1.125rem;
                margin-right: 20px;
              }

              ul {
                display: block;
                float: left;

                li {
                  padding: 15px 10px;
                  width: 25%;
                  float: left;
                  text-align: center;
                  clear: none;
                  border-right: 1px solid $white;

                  a {
                    color: $white;
                  }

                  i {
                    display: none;
                  }

                  &:last-child {
                    border: none;
                  }

                  &.active {
                    background: $white;
                    color: $blue;
                  }
                }
              }
            }
            &.search-toggler {
              height: auto;
              border-bottom: 1px solid $gray-lighter;
              border-top: 1px solid $gray-lighter;
              padding: 10px;

              > a {
                display: none;
              }

              .searchbar {
                height: auto;
                width: 100%;
                position: relative;
                top: auto;

                form {
                  padding: 2px;
                }
              }
            }
            &.menu-toggler {
              display: block;
              height: 60px;
              width: 60px;
              padding-top: 18px;
              position: fixed;
              top: 10px;
              right: 10px;
              z-index: 999;
              transition: all 200ms;

              a {
                color: $white;
              }

              &.close {
                top: 0;
                right: 0;

                a {

                  font-size: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
