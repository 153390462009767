.full-image {
  background-size: cover;
  background-position: center;
  padding: 40px;
  position: relative;

  &.bigger{
    padding: 120px 40px;

    @include respond-below(md){
      padding: 40px 20px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0,0,0, 0.25);
    width: 100%;
    height: 100%;
  }

  h3 {
    color: $white;
    font-size: 2rem;
    margin-top: 0;
  }

  .overlay-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @supports(display: grid){
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-gap: 40px;
    }

    .left-content {
      flex: 0 0 33.33%;
      padding-right: 20px; 

      @supports(display: grid){
        padding-right: 0;
      }

      p {
        font-weight: $font-weight-bold;
      }
    }
    
    .right-content {
      flex: 0 0 66.66%;
    }

    p {
      color: $white;
    }

    @include respond-below(md) {
      grid-template-columns: 1fr;
    }
  }
}
