#hero {
  width: 100%;
  display: flex;
  height: 100vh;
  background-size: cover;
  background-position: center;
  align-items: center;
  justify-content: center;

  &.klein {
    height: 450px;
  }

  .hero-title {
    text-align: center;
    max-width: 1260px;

    h1 {
      font-size: 4rem;
      font-family: "Roboto Slab", serif;
      font-weight: $font-weight-bold;
      text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
      margin: 0;
      color: $white;
      line-height: 1.2;
      hyphens: auto;
      opacity: 1;

      @include respond-above(sm) { font-size: 5rem; }
      @include respond-above(md) { font-size: 5rem; }
      @include respond-above(lg) { font-size: 6.5rem; line-height: 1; }
    }

    h2 {
      margin: 0;
      font-size: 1.5rem;
      font-weight: $font-weight-medium;
      text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
      color: $white;

      @include respond-above(sm) { font-size: 1.25rem; }
      @include respond-above(md) { font-size: 1.5rem; }
      @include respond-above(lg) { font-size: 2rem; }
    }
  }
}
