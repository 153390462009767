// Buttons
// --------------------------------------------------

.button {
  @include button;

  &.read-more {
    color: $blue;
  }
}
