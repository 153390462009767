.full-video {
  padding: 0;
  height: calc(100vh - 45px);

  .video-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -2;
    min-width: 100vw;
    height: 100vh;

    video {
      position: absolute;
      width: auto;
      top: 50%;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      max-width: none;
      transform: translate(-50%, -50%);
    }
  }

  .wrapper {
    width: 100%;
    height: 100%;

    .overlay-content {
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;

      .hero-title {
        text-align: center;
        max-width: 1260px;

        h1 {
          font-size: 4rem;
          font-family: "Roboto Slab", serif;
          font-weight: $font-weight-bold;
          text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
          margin: 0;
          color: $white;
          line-height: 1.2;
          hyphens: auto;

          @include respond-above(sm) { font-size: 5rem; }
          @include respond-above(md) { font-size: 5rem; }
          @include respond-above(lg) { font-size: 8.5rem; line-height: 1; }
        }

        h2 {
          margin: 0;
          font-size: 1.5rem;
          font-weight: $font-weight-medium;
          text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
          color: $white;

          @include respond-above(sm) { font-size: 1.25rem; }
          @include respond-above(md) { font-size: 1.5rem; }
          @include respond-above(lg) { font-size: 2rem; }
        }

        &.fade {
          opacity: 0;
          transition: opacity 800ms;
          transition-delay: 1000ms;
        }
      }
    }
  }

  @include respond-below (lg) {
    height: calc(100vh);

    .wrapper {
      .overlay-content {
        a {
          font-size: 1.5rem;
        }
      }
    }
  }

  @include respond-below (md) {
    .wrapper {
      .overlay-content {
        a {
          font-size: 1.25rem;
        }
      }
    }
  }

  @include respond-below (sm) {
    .wrapper {
      .overlay-content {
        a {
          font-size: 1rem;
        }
      }
    }
  }
}
