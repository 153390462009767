// Layout
// --------------------------------------------------

.layout-container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
}

.wrapper {
    width: 100%;
    max-width: 1260px;
    margin: 0 auto;
    position: relative;
    display: block;

    @include respond-below(xl) {
      padding: 0 40px;
    }
    @include respond-below(md) {
      padding: 0 20px;
    }
}
