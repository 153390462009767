.contact {
  padding: 40px 0;

  .wrapper {
    .form {
      background: $white;
      @include grid(1,1,2,2,2);
      
      .block-title {
        padding: 10px 20px;
        border-bottom: 1px solid $gray-lighter;

        h3 {
          margin: 0;
          color: $blue;
          font-size: 1.5rem;
        }
      }

      .block-content {
        padding: 20px;

        .nf-before-form-content {
          margin-bottom: 20px;
        }

        .nf-field-container {
          margin-bottom: 10px;  
        }

        label {
          font-size: 1rem;
          font-weight: $font-weight-regular;
        }
        span {
          font-size: 0.875rem;
          font-weight: $font-weight-bold;
        }
        input,
        textarea {
          background: $gray-lightest;
          border: 0;
          max-width:100%;
          font-size: 1rem;
          color: $gray;
        }
        input[type="button"] {
          background: $blue;
          color: $white;
          padding: 10px 20px;
          font-size: 1rem;
        }
      }
    }
  }
}
