footer {
  background: $secondary-color;
  float: left;
  width: 100%;

  .bottom-bar {
    height: 35px;
    background: darken($secondary-color, 10%);
    padding: 7px 0;
    width: 100%;
    float: left;

    p {
      margin: 0;
      font-size: 0.85rem;
      color: $white;
    }
  }

  .contact {
    flex: 0 0 33.33%;
  }

  .top-bar {
    float: left;
    width: 100%;
    position: relative;
    padding: 40px 0;

    > .wrapper {
      @include grid(1, 1, 2, 3, 3, 40px);

      .logo {
        display: flex;
        > div {
          float: left;
          width: 50%;
          background: $white;
          padding: 25px;

          &.logo-asiatico {
            background: $white;
          }
        }
      }

      ul {
        list-style: none;
        clear: left;
        float: left;
        margin: 0;
        padding: 0;
        margin-top: 10px;
        width: 100%;

        li {
          border-bottom: 1px solid $white;
          padding: 10px 0;
          width: 100%;
          color: $white;

          i {
            color: $white;
            width: 30px;
          }

          a {
            text-decoration: none;
            font-size: 0.875rem;
          }

          &:last-child {
            border: 0;
            padding-bottom: 0;
          }
        }
      }

      #to-top {
        padding: 18px;
        width: 70px;
        height: 70px;
        text-align: center;
        background: $red;
        position: absolute;
        right: 0;
        bottom: -55px;
        font-size: 1.5rem;

        i {
          color: $white;
        }
      }
    }
  }
}
