.features-bar {
  padding-bottom: 40px;

  .wrapper {
    
    @include grid(1, 1, 2, 3, 3, 40px);

    > a {
      background: $blue;
      padding: 10px 30px;
      display: flex;
      align-items: center;

      i {
        font-size: 2.5rem;
        color: $white;
        padding: 20px 30px 20px 0;
        margin-right: 30px;
        border-right: 1px solid $white;
      }

      p {
        color: $white;
        font-size: 1.25rem;
        margin: 0;
      }

      &.help {
        background: $red;
      }
      &.fresh {
        background: $gray-dark;
      }

      @include respond-between (md, lg) {
        &:last-child {
          grid-column: 1 / -1;
        }
      }
    }
  }
}
