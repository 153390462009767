.forms {
  padding: 20px 0;
  background: $white;
  margin-bottom: 40px;

  .wrapper {
    display: flex;
    

    > div {
      background: $white;
      display: flex;
      flex-direction: column;
      max-width: 50%;
      padding-right: 40px;
      justify-content: center;
      height:100%;

      h3 {
        margin: 0;
        width: 100%;
      }
      p {
        width: 100%;
      }
      span {
        font-size: 0.75rem;
        color: $gray-light;
      }
      .nf-form-cont {
        display: inline-flex;

      }
      .button {
        background: $blue;
        color: $white;

        &:hover {
          background: $white;
          color: $blue;
        }
      }
    }

    .nf-field-container {
      margin-bottom: 10px;
    }

    .nf-form-fields-required {
      margin-bottom: 10px;
      font-size: 0.875rem;
    }

    .checkbox-container {
      // max-width: 400px;
      margin: 0 auto;

      label {
        font-size: 0.875rem;
        font-weight: $font-weight-regular;
      }
      
      input {
        vertical-align: top;
        margin-top: 5px;
      }
    }
  }
}


/* Changes by Wilco 28-11-18 */
.forms .nf-before-form-content {
  display: none;
}

.forms nf-fields-wrap nf-field {
  display: inline-block;
  vertical-align: middle;
}

.forms nf-fields-wrap nf-field .button {
  padding: 0.85em 1.5em;
}

.forms #nf-form-4-cont {
  margin-top: 22px;
}

.forms .wrapper > div {
  margin-top: 14px;
}

.forms .nf-error-msg {
  position: absolute;
  bottom: -25px;
}

@media only screen and (max-width: 768px) {
  .forms .wrapper {
    display: block;
  }

  .forms .wrapper > div {
    max-width: 100%;
    padding: 0px;
    display: block;
  }

  .forms #nf-form-4-cont {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .forms .nf-error-msg {
    position: relative;
    bottom: 0;
  }
}