.groups {
  padding: 40px 0;

  > .wrapper {
    .accordion {
      background: $white;

      .acc-head {
        padding: 10px 20px;
        border-bottom: 1px solid $gray-lighter;

        h3 {
          margin: 0;
          display: inline-block;
        }

        a {
          display: inline-block;
          color: $blue;
          font-size: 1.25rem;
          margin-left: 20px;

          i {
            position: absolute;
            top: 20px;
            transform-origin: center;
            transition: transform 400ms;
          }

          &.is-open {
            i {
              transform: rotateX(180deg);
            }
          }
        }

        form {
          position: absolute;
          right: 20px;
          top: 14px;

          input {
            border-radius: 0;
            padding: 5px 10px;
            font-size: 0.875rem;
            border-color: $gray-lighter;
            padding-right: 40px;

            &[type='submit'] {
              position: absolute;
              right: 0;
              top: 0;
              letter-spacing: -1px;
              font-size: 0;
              border: 0;
              background: transparent no-repeat;
              height: 30px;
              width: 30px;
              padding: 0;
              background-image: url('../images/search.svg');
              background-size: 18px 18px;
              background-position: center;
              cursor: pointer;
            }
          }

          @include respond-below (md) {
            position: relative;
            top: auto;
            right: auto;
            margin-top: 10px;
          }
        }
      }

      .acc-body {
        display: none;
        overflow: hidden;

        ul {
          margin: 0;
          padding: 10px 20px;
          list-style: none;
          // @include grid(2,2,4,4,4,0 40px);

          @include respond-above (md) {
            columns: 2;
            column-gap: 20px;
          }

          @include respond-above (lg) {
            columns: 4;
            column-gap: 40px;
          }

          li {
            a {
              color: $black;
              font-size: 1.25rem;
              font-weight: $font-weight-bold;
              text-decoration: none;

              span {
                display: inline-block;
                color: $gray-lighter;
                padding-left: 10px;
              }
            }
          }
        }
      }

      &.is-open {
        .acc-body {
          display: block;
        }
      }
    }
  }
}
